.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #F54E5E;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.layout {
    display: flex;
    padding: 2em;
    flex-wrap: wrap;
    margin-top: -1em;
    margin-left: -3.5em;
    align-items: center;
}

.layout:not(:first-of-type) {
    margin-top: 3em;
}

.layout:nth-child(even) {
    flex-direction: row;
}

.layout__item {
    padding-top: 1em;
    padding-left: 3.5em;
}

.layout__item--body {
    flex-grow: 9999;
    flex-basis: 15rem;
    text-align: left;
}

.layout__item--figure {
    margin-right: auto;
    flex-grow: 1;
}

@supports (max-width: max-content) {
    min-width: 15rem;
    max-width: max-content;
    flex-basis: 15rem;
    flex-grow: 99999;
}

h3 {
    font-size: 2em;
    line-height: 1.2;
}

img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    vertical-align: middle;
}

.layout__item *+* {
    margin-top: 1em;
}

.supportedplatforms {
    display: flex;
    flex-direction: row;
}

.no-bullets {
    list-style-type: none;
}

ul {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: row;
}

li {
    padding-left: 1.3em;
}